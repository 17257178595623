<template>
	<div class="block-inner">
		<header v-html="question"></header>
		<div ref="items" class="items">
			<ol v-if="items.lhs" class="lhs">
				<li v-for="item in items.lhs" :key="item.id" :data-id="item.id" :class="getItemClasses(item.id, 'lhs')" @click="setSelected(item.id, 'lhs')">
					<img v-if="item.type === 'image'" :src="item.url" @load="() => updateLines(answers)" />
					<span v-else>{{ item.label }}</span>
					<span class="dot"></span>
				</li>
			</ol>
			<ol v-if="items.rhs" class="rhs">
				<li v-for="item in items.rhs" :key="item.id" :data-id="item.id" :class="getItemClasses(item.id, 'rhs')" @click="setSelected(item.id, 'rhs')">
					<span class="dot"></span>
					<img v-if="item.type === 'image'" :src="item.url" @load="() => updateLines(answers)" />
					<span v-else>{{ item.label }}</span>
				</li>
			</ol>
			<div ref="lines" class="lines"></div>
		</div>
	</div>
</template>

<script>
	import {jsonToHtml} from '../../inc/text';
	import answerMixin from '../../mixins/answerMixin';
	import Store from '../../inc/store';
	import ObjectStore from '../../inc/objectStore';
	import Bus from '../../inc/bus';
	import {showCourseFeedback} from '../../inc/courseUtils';
	import {debounce} from 'vue-debounce';

	export default {
		mixins: [answerMixin],
		props: {
			block: {
				type: Object,
				required: true
			}
		},
		data() {
			return {
				selectedItem: null,
				answers: []
			};
		},
		computed: {
			question() {
				return jsonToHtml(this.block.content);
			},
			isSubmitted() {
				return Store.groupAnswers.some(answer => answer.blockId === this.block.id);
			},
			groupAnswer() {
				return Store.groupAnswers.find(answer => answer.blockId === this.block.id);
			},
			correctAnswers() {
				return this.groupAnswer.correctAnswers || [];
			},
			showFeedback() {
				return showCourseFeedback(this.block.type);
			},
			items() {
				return this.block.items || {};
			},
			isLocked() {
				return Boolean(Store.courseGroupProgress.timeGraded || Store.session.timeEnded);
			}
		},
		watch: {
			answers(answers) {
				this.updateLines(answers);

				if(!this.isLocked) {
					this.sendAnswer([answers]);
				}
			}
		},
		created() {
			this.fetchImageUrls();

			// Unshuffle items if the user has answered the question
			this.items.lhs = this.unshuffle(this.items.lhs, 0);
			this.items.rhs = this.unshuffle(this.items.rhs, 1);

			this.$nextTick(() => {
				if(this.groupAnswer) {
					this.answers = this.groupAnswer.answers[0];
				}

				this.updateLines(this.answers);
			});

			Bus.on('clickAnywhere', e => {
				if(!e.target.closest('.rhs li, .lhs li')) {
					this.selectedItem = null;
				}

				if(e.target.closest('.line') && !this.isLocked) {
					this.removeLine(e.target);
				}
			});

			window.addEventListener('resize', this.onResize());
		},
		destroyed() {
			window.removeEventListener('resize', this.onResize());
		},
		methods: {
			unshuffle(items, answerIndex) {
				if(!this.isSubmitted || !this.answers.length || !Array.isArray(items)) {
					return items;
				}

				return items
					.map((item, index) => {
						item.sort = this.answers.findIndex(answer => answer[answerIndex] === item.id);

						if(item.sort === -1) {
							item.sort = index;
						}

						return item;
					})
					.sort((a, b) => a.sort - b.sort)
					.map(item => {
						Reflect.deleteProperty(item, 'sort');

						return item;
					});
			},
			onResize() {
				return debounce(() => this.updateLines(this.answers), 100);
			},
			setSelected(itemId, side) {
				if(this.selectedItem) {
					const isSameItem = this.selectedItem === itemId;
					const isSameSide = this.items[side].some(item => item.id === this.selectedItem);

					if(!isSameItem && !isSameSide) {
						const answer = [
							this.selectedItem,
							itemId
						];

						if(side === 'lhs') {
							answer.reverse();
						}

						this.answers.push(answer);
					}

					this.selectedItem = null;
				}
				else if(!this.answers.flat().includes(itemId)) {
					this.selectedItem = itemId;
				}
			},
			getUserAnswer() {
				const groupAnswers = Store.groupAnswers.filter(answer => answer.blockId === this.block.id);

				if(groupAnswers.length) {
					return groupAnswers[0];
				}

				return {};
			},
			updateLines(answers) {
				if(typeof this.$refs.lines === 'undefined') {
					return;
				}

				this.$refs.lines.innerHTML = '';

				for(const answer of answers) {
					const start = this.$refs.items.querySelector('.lhs [data-id="' + answer[0] + '"] .dot');
					const end = this.$refs.items.querySelector('.rhs [data-id="' + answer[1] + '"] .dot');
					const classes = this.isSubmitted && this.showFeedback && this.getItemClasses(answer[0], 'lhs');

					if(start && end) {
						const line = document.createElement('div');
						const height = 10;

						const x1 = start.offsetLeft + (height / 2);
						const y1 = start.offsetTop + (height / 2);
						const x2 = end.offsetLeft + (height / 2);
						const y2 = end.offsetTop + (height / 2);

						const length = Math.sqrt(((x2 - x1) * (x2 - x1)) + ((y2 - y1) * (y2 - y1))) + height;

						line.classList.add('line');

						if(!this.isLocked) {
							line.dataset.start = answer[0];
							line.dataset.end = answer[1];
						}

						if(classes) {
							if(classes.correct === true) {
								line.classList.add('correct');
							}
							else {
								line.classList.add('incorrect');
							}
						}

						line.style.top = ((y1 + y2) / 2) - (height / 2) + 'px';
						line.style.left = ((x1 + x2) / 2) - (length / 2) + 'px';

						line.style.width = length + 'px';
						line.style.transform = 'rotate(' + (Math.atan2((y1 - y2), (x1 - x2)) * (180 / Math.PI)) + 'deg)';

						this.$refs.lines.append(line);
					}
				}
			},
			removeLine(line) {
				const {start, end} = line.dataset;

				this.answers = this.answers.filter(ua => ua[0] !== start && ua[1] !== end);
			},
			getItemClasses(itemId, side) {
				const classes = {
					selected: this.selectedItem === itemId
				};

				if(this.isSubmitted && this.showFeedback) {
					const answer = this.answers.find(a => {
						if(side === 'lhs' && a[0] === itemId) {
							return true;
						}
						if(side === 'rhs' && a[1] === itemId) {
							return true;
						}

						return false;
					});

					classes.correct = answer && this.correctAnswers.some(ca => ca[0] === answer[0] && ca[1] === answer[1]);
					classes.incorrect = !classes.correct;
				}

				return classes;
			},
			fetchImageUrls() {
				if(!this.items || !this.items.lhs) {
					return;
				}

				const {lhs, rhs} = this.items;

				if(Array.isArray(lhs)) {
					for(const i in lhs) {
						if(lhs[i].type === 'image') {
							ObjectStore.getFileUrl(lhs[i].file).then(url => {
								this.$set(this.items.lhs[i], 'url', url);
							});
						}
					}
				}

				if(Array.isArray(rhs)) {
					for(const i in rhs) {
						if(rhs[i].type === 'image') {
							ObjectStore.getFileUrl(rhs[i].file).then(url => {
								this.$set(this.items.rhs[i], 'url', url);
							});
						}
					}
				}
			}
		}
	};
</script>

<style lang="scss" scoped>
	.items {
		display: flex;
		flex-flow: row nowrap;
		gap: $default_padding * 4;
		position: relative;
	}

	ol {
		flex: 1;
		list-style-type: none;
		margin: 0;
		padding: 0;
		max-width: 500px;

		&.lhs li {
			justify-content: space-between;
		}

		&.rhs li {
			justify-content: flex-start;
		}
	}

	li {
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		gap: 10px;
		background: $color__white;
		font-size: 1.25rem;
		width: 100%;
		border: none;
		box-shadow: $box_shadow__input;
		border-radius: $border_radius;
		padding: 0.5rem 1rem;
		margin-bottom: $default_padding / 2;
		line-height: 1em;
		transition: box-shadow 0.2s ease-out;
		cursor: pointer;

		.dot {
			width: 10px;
			height: 10px;
			border-radius: 50%;
			background-color: $color__semi_dark;
			flex: 0 0 10px;
		}

		&.selected {
			outline: 3px solid $color__light_blue;

			.dot {
				background-color: $color__light_blue;
			}
		}

		&.correct .dot {
			background-color: $color__green;
		}

		&.incorrect .dot {
			background-color: $color__red;
		}

		img {
			max-width: 95%;
			max-height: 150px;
		}
	}

	.lines::v-deep {
		position: absolute;

		.line {
			position: absolute;
			height: 10px;
			background-color: $color__light_blue;
			border-radius: 10px;
			box-shadow: 0 0 0 1px $color__background;

			&.correct {
				background-color: $color__green;
			}

			&.incorrect {
				background-color: $color__red;
			}

			&[data-start]:hover {
				background-color: $color__red;
				cursor: pointer;
			}
		}
	}
</style>